/*!
 * bootstrap-navbar-toggle v0.9.0 (https://skywalkapps.github.io/bootstrap-navbar-toggle)
 * Copyright 2017 Martin Staněk
 * Licensed under MIT
 */
.navbar-toggle {
  line-height: 1;
}
.navbar-toggle.navbar-toggle-left {
  float: left;
  margin-left: 15px;
}
.navbar-toggle-label {
  display: inline-block;
  vertical-align: top;
  color: #888;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.navbar-toggle-label + .navbar-toggle-icon {
  margin-left: 4px;
}
.navbar-inverse .navbar-toggle-label {
  color: #fff;
}
.navbar-toggle-icon {
  display: inline-block;
}
.navbar-toggle-icon > .icon-bar + .icon-bar {
  margin-top: 0;
}
/* Icon 1 */
.navbar-toggle-icon {
  position: relative;
  width: 22px;
  height: 14px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.navbar-toggle-icon span {
  display: block;
  position: absolute;
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
}
.navbar-toggle.collapsed .icon-bar:nth-child(1) {
  top: 0;
}
.navbar-toggle.collapsed .icon-bar:nth-child(2) {
  top: 6px;
}
.navbar-toggle.collapsed .icon-bar:nth-child(3) {
  top: 12px;
}
.navbar-toggle:not(.collapsed) .icon-bar:nth-child(1) {
  top: 6px;
  transform: rotate(45deg);
}
.navbar-toggle:not(.collapsed) .icon-bar:nth-child(2) {
  opacity: 0;
}
.navbar-toggle:not(.collapsed) .icon-bar:nth-child(3) {
  top: 6px;
  transform: rotate(-45deg);
}
