[class$="riddle1"], [class*="riddle1 "] {
  font-family: 'riddle1';
  font-style: normal;
  font-weight: normal;
 
  /* fix buttons height */
  line-height: 1em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
}
 
.flame-iconriddle1 { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe802;&nbsp;'); }
.quiz-iconriddle1 { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe803;&nbsp;'); }
.riddle-emoriddle1 { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe804;&nbsp;'); }
.riddle-iconriddle1 { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe805;&nbsp;'); }
.staff-iconriddle1 { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe806;&nbsp;'); }
.staff-icon2riddle1 { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe807;&nbsp;'); }
.qmark-iconriddle1 { *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe808;&nbsp;'); }